  /**
 * @function: Likes para ekohub
 * @description: 
 */

  $(function() {
    $(".heart").on("click", function() {
      $(this).toggleClass("is-active");
    });
  });
