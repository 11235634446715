  /**
 * @function: imMaxlengthCounter
 * @description: Limit characters on inputs with information text
 */

  function imMaxlengthCounter(selector) {
      $(selector).each(function(){
          var $this = $(this),
              limit = $this.attr('maxlength'),
              length = $this.val().length;

          $this.after("<div class='text-info'><span>" + length + "</span> Caracteres</div>");

          $this.on('keyup', function(){
              length = $this.val().length;

              if( length <= limit ) {
                  $this.siblings('.text-info').find('span').text(length);
              }   
          });
      });
  }
  imMaxlengthCounter('[maxlength]');